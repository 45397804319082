<template>
  <div class="row">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="false"></loading>
    <!-- Filter Field -->
    <div class="py-1 m-0 p-0 col-12">
      <div class="md-form mb-0">
        <div class="input-group-icon input-group-icon-right mb-0">
          <div class="md-form mb-0">
                <span class="input-icon input-icon-right">
                  <i class="ti-search"></i>
                </span>
            <input
                  v-on:keyup="keyMonitor"
                   :title="placeholder"
                    ref="searchTerm"
                   class="md-form-control" type="text"
                   v-model="searchTerm"
                   >
            <label >{{ placeholder }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-12">
      <div class="custom-scroll" style="max-height: 200px">
        <table class="table table-hover table-inbox" id="table-inbox">
          <tbody class="rowlinkx" data-link="row">
          <tr v-for="(item, index) in filteredOptions"  v-on:click="rowClick(item)" :data-idx="index" :key="index" :data-id="index" >
            <td class="check-cell rowlink-skip">
              <label class="checkbox check-single">
                <input class="option-check" type="checkbox" v-on:change="toggleOption(item)"  :checked="isIncluded(item)"  :value="item.id_customer" >
                <span></span>
              </label>
            </td>
            <td>{{ item[labelField] }}</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'MultipleSelect',
  props: {
    rowClick: {
      type: Function,
      required: false,
      default: (row) => {

      }
    },
    delayTime: {
      type: Number,
      default: 400
    },

    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    apiUrl: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    emptyMessage: {
      type: String,
      default: global.instanceApp.$i18n.t('noting selected')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    fetchOptions: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    searchField: {
      type: String,
      default: ''
    },
    labelField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      waiting: null,
      searchTerm: null,
      selectedValue: null
    }
  },
  methods: {
    keyMonitor: function (event) {
      let _this = this
      // Can get key pressed on "event.key"
      if (this.waiting) {
        window.clearTimeout(this.waiting)
      }

      this.waiting = setTimeout(function () {
        _this.isLoading = true
        if (_this.searchTerm) {
          _this.fetchOptions(_this.searchTerm).then(res => {
            _this.isLoading = false
          }).catch(() => {
            _this.isLoading = false
          })
        } else {
          _this.options = []
          _this.isLoading = false
        }
      }, this.delayTime)
    },
    isIncluded: function (val) {
      let _me = this
      let selected = this.value.find((x) => {
        return x[_me.idField] === val[_me.idField]
      })

      if (selected !== undefined) {
        return true
      }

      return false
    },
    toggleOption (val) {
      if (val != null) {
        if (!this.isIncluded(val)) {
          let valueObject = {}

          valueObject.id = val[this.idField]
          valueObject.label = val[this.labelField]

          valueObject[this.idField] = val[this.idField]
          valueObject[this.labelField] = val[this.labelField]

          this.value.push(valueObject)
        } else {
          this.removeSelectedOption(val)
        }

        this.searchTerm = null
        this.$refs.searchTerm.focus()
      }
    },
    removeSelectedOption (val) {
      if (val != null) {
        let _this = this
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element[_this.idField] === val[_this.idField]
        })
        // remove element from selected options
        this.value.splice(index, 1)
      }
    }
  },
  computed: {
    filteredOptions: function () {
      let _this = this

      let data = _this.options.filter(function (option) {
        return !_this.isIncluded(option)
      })

      return data.concat(this.value)
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      // watch data change
    }
  }
}
</script>

<style scoped>
  .buttonClose:not(:disabled):not(.disabled) {
    cursor: pointer;
    font-size: 12px;
  }

  .option-disabled {
    cursor: not-allowed;
    color: #83dadb;
  }
</style>
