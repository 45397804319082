<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <!-- Vendedor -->
              <div class="row mt-2">
                <div>
                  <multiple-select labelField="nome" :options="employeeList" :id-field="'id_employee'"
                    :placeholder="this.$i18n.t('form.task.seller')" :fetchOptions="employeeFetchOptions"
                    :value="filters.filEmployees" />
                </div>
              </div>

              <!-- Criado por -->
              <div class="row">
                <div>
                  <multiple-select labelField="nome" :options="userList" :id-field="'id'"
                    :placeholder="this.$i18n.t('form.task.created_by')" :fetchOptions="userFetchOptions"
                    :value="filters.filUsers" />
                </div>
              </div>

              <!-- Cliente -->
              <div class="row">
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'"
                    :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions"
                    :value="filters.filCliente" />
                </div>
              </div>

              <!-- Processo -->
              <div class="px-3">
                <div class="md-form mb-0">
                  <input :disabled="true" id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <!-- ETD -->
              <h6 class="ml-3 mb-2 mt-4">ETD</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form my-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form my-0">
                      <span class="input-icon input-icon-right">
                        <i class="ti-calendar"></i>
                      </span>
                        <input data-index="2" :title="this.$i18n.t('form.booking-control.etd')"
                               id="etd_begin_date" ref="etd_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filEtdInicio)">
                        <label :class="{'active': filters.filEtdInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form my-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4 mt-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.booking-control.etd')"
                               id="etd_end_date" ref="etd_end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filEtdFim, undefined, null)">
                        <label :class="{'active': filters.filEtdFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- DDL -->
              <h6 class="ml-3 mb-2 mt-4">{{ this.$i18n.t('form.booking-control.ddl') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form my-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form my-0">
                      <span class="input-icon input-icon-right">
                        <i class="ti-calendar"></i>
                      </span>
                        <input data-index="2" :title="this.$i18n.t('form.booking-control.ddl')"
                               id="ddl_begin_date" ref="ddl_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filDdlInicio, undefined,null)">
                        <label :class="{'active': filters.filDdlInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form my-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4 mt-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.booking-control.ddl')"
                               id="ddl_end_date" ref="ddl_end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filDdlFim, undefined, null)">
                        <label :class="{'active': filters.filDdlFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operation -->
              <h6 class="mb-0 mt-4 ml-3">{{ $t('operation type') }}</h6>
              <div class="row mb-3">
                <!-- Filter Operation  -->
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Importação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="operation_impo" value="2" type="checkbox" ref="operation_impo"
                          :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Exportação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning">
                        <input id="operation_expo" value="1" type="checkbox" ref="operation_expo"
                          :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12 px-0">
            <div class="card">
              <div class="card-body">

                <!-- Header and Actions -->
                <div class="flexbox px-0 py-3 mb-3">
                  <div class="d-flex align-items-center">
                    <h5 class="box-title mb-3 mt-1">Grupos de Contratos</h5>
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link v-show="isBookingAdmin || isSuperAdmin"
                      :to="{name: 'BookingContractGroupNew'}" class="btn btn-sm btn-outline-light pull-right">
                      Novo Grupo
                    </router-link>
                    <!-- <router-link v-show="isBookingRequester || isSuperAdmin"
                      :to="{name: 'BookingRequest'}" class="btn btn-sm btn-outline-light pull-right">
                      Pedir
                    </router-link>
                    <router-link v-show="isBookingAdmin || isSuperAdmin"
                      :to="{name: 'BookingConfig'}" class="btn btn-sm btn-outline-light pull-right">
                      Configurações
                    </router-link> -->
                    <router-link v-show="isBookingAdmin || isSuperAdmin"
                      :to="{name: 'BookingContractIndex'}" class="btn btn-sm btn-outline-light pull-right">
                      Contratos
                    </router-link>
                  </div>
                </div>

                <!-- Table -->
                <div class="table-responsive px-0">
                  <vuetable ref="vuetable"
                    :noDataTemplate="this.$i18n.t('empty')"
                    :api-url="endpoint"
                    :fields="vTableFields"
                    :sort-order="sortOrder"
                    :css="css.table"
                    pagination-path="pagination"
                    :per-page="20"
                    :multi-sort="multiSort"
                    :append-params="filter"
                    multi-sort-key="shift"
                    :http-options="httpOptions"
                    track-by="id_process"
                    @vuetable:pagination-data="onPaginationData"
                    @vuetable:loading="onLoading"
                    @vuetable:loaded="onLoaded"
                    @vuetable:cell-clicked="onRowClicked">
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                  :css="css.pagination" @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

import BookingService from '@/services/BookingService'
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'

import Details from '@/views/Booking/Detail.vue'
import OptionsList from './../../OptionsList.vue'
import ApplyForm from './../../ApplyForm.vue'

export default {
  name: 'BookingList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.booking') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      endpoint: process.env.VUE_APP_APIENDPOINT + 'v2/booking/contract/group',
      multiSort: true,
      statusOptions: [],
      employeeList: [],
      userList: [],
      customerList: [],
      activeFilterList: {
        begin_date: null,
        end_date: null
      },
      filter: {},
      fieldsTable: [
        {
          name: 'nome',
          title: this.$i18n.t('form.booking-control.grupo'),
          sortField: 'nome',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'contratos',
          title: this.$i18n.t('form.booking-control.contratos'),
          sortField: 'contratos',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      sortOrder: [
        { field: 'nome', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    MultipleSelect,
    Loading
  },
  // Apply filters to local filter
  beforeMount () {
    this.statusFetchOptions()

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#etd_begin_date, #etd_end_date, #ddl_begin_date, #ddl_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    buildFilters () {
      // Vendedor
      this.filter.vendedor = (this.filters.filEmployees) ? this.filters.filEmployees.map(x => (x.id)) : []

      // Solicitante
      this.filter.solicitante = (this.filters.filUsers) ? this.filters.filUsers.map(x => (x.id)) : []

      // Solicitante
      this.filter.cliente = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []

      // Processo
      if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      // ETD
      this.filter.etd_begin_date = this.filters.filEtdInicio
      this.filter.etd_end_date = this.filters.filEtdFim

      // DDL BBK
      this.filter.ddl_begin_date = this.filters.filDdlInicio
      this.filter.ddl_end_date = this.filters.filDdlFim

      // Operação
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }

      this.filter.id_status = null
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuickSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuickSidebar()

      // Vendedor
      this.filters.filEmployees = []

      // Solicitante
      this.filters.filUsers = []

      // Solicitante
      this.filters.filCliente = []

      this.filters.filProcesso = null

      this.filters.filEtdInicio = null
      this.filters.filEtdFim = null
      this.filters.filDdlInicio = null
      this.filters.filDdlFim = null

      // Operação
      this.filters.operation = []
      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2

      this.filter.id_status = null

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()

      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    statusFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        BookingService.getStatusOptions(filters).then(res => {
          _this.statusOptions = res.data.data
          resolve(_this.statusOptions)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    onRowClicked (row) {
      this.$router.push({ name: 'BookingContractGroupView', params: { id: row.data.id } })
    },
    /**
     * Display modal with Process details and files if process has files (active tab "Timeline")
     * @param data Process data from line selected
     */
    showDetails (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(Details)
        var detailsInstance = new ComponentClass({
          data: {
            user: me.user,
            booking: data,
            statusOptions: me.statusOptions
          }
        })
        detailsInstance.$mount()
        detailsInstance.$on('apply', this.onApply)
        detailsInstance.$on('leave', this.onLeave)
        let detailsProcessContent = detailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null,
          'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getFuncionarios(filters).then(res => {
          _this.employeeList = res.data.data
          resolve(_this.employeeList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    userFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        EmployeeService.getUsuarios(filters).then(res => {
          _this.userList = res.data.data
          resolve(_this.userList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    onActionsClicked (row) {
      this.showOptions(row.rowData)
    },
    showOptions (data) {
      let me = this
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(OptionsList)
        var optionsInstance = new ComponentClass({
          data: {
            user: me.user,
            booking: data,
            status: this.statusColor(data.situacao)
          }
        })
        optionsInstance.$mount()
        optionsInstance.$on('delete', this.onDelete)
        optionsInstance.$on('update', this.onUpdateStatus)
        optionsInstance.$on('apply', this.onApply)
        optionsInstance.$on('leave', this.onLeave)
        let optionsProcessContent = optionsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, optionsProcessContent, null,
          'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    onDelete (id) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja excluir este booking?',
        'warning', 'Sim', 'Não', (e) => this.deleteBooking(id)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro excluído com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível excluir o registro!', 'error')
        }

        this.$refs.vuetable.refresh()
      })
    },
    async deleteBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.deleteBooking(id).then(res => {
        // if (res.httpCode === 200 && res.data.status === 'ok') {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onUpdateStatus (booking, newStatus) {
      let currentStatusLabel = this.statusOptions[booking.situacao]
      let newStatusLabel = this.statusOptions[newStatus]
      let message = `Confirma a alteração de situação de "${currentStatusLabel}" para "${newStatusLabel}"?`

      this.$alertSwal.modalConfirm('', message,
        'warning', 'Sim', 'Não', (e) => this.updateBookingStatus(booking.id, newStatus)).then((res) => {
        if (res.value.success) {
          this.$alertSwal.toast(res.value.message)
        } else {
          let message = `Não foi possível alterar o registro!<br/> ${res.value.message}`
          this.$alertSwal.toast(message, 'error')
        }
        this.$refs.vuetable.refresh()
      })
    },
    async updateBookingStatus (bookingId, newStatusKey) {
      let _this = this
      _this.isLoading = true

      let data = {
        id: bookingId,
        situacao: newStatusKey
      }

      let resp = await BookingService.updateBookingStatus(data).then(res => {
        _this.isLoading = false
        return {
          success: true,
          message: res.data.message,
          record: res.data.data
        }
      }).catch((e) => {
        _this.isLoading = false
        return {
          success: false,
          message: e.message
        }
      })

      return resp
    },
    onApply (id, user) {
      var ComponentClass = Vue.extend(ApplyForm)
      var applyInstance = new ComponentClass({
        data: {
          user: user,
          booking: id,
          option: 'applying'
        }
      })
      applyInstance.$mount()
      applyInstance.$on('refresh', this.refreshPage)
      let optionsProcessContent = applyInstance.$el
      global.instanceApp.$alertSwal.modalAlert(null, optionsProcessContent, null,
        'no-padding', { showConfirmButton: false }).then((result) => { })
    },
    refreshPage () {
      this.$refs.vuetable.refresh()
    },
    onLeave (id, user) {
      let data = {
        booking: id,
        user: user
      }
      this.$alertSwal.modalConfirm('', 'Você tem certeza que deseja devolver este booking?',
        'warning', 'Sim', 'Não', (e) => this.leaveBooking(data)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Registro devolvido com sucesso!')
        } else {
          this.$alertSwal.toast('Não foi possível devolver o registro!', 'error')
        }

        this.$refs.vuetable.refresh()
      })
    },
    async leaveBooking (id) {
      let _this = this
      _this.isLoading = true
      await BookingService.leaveBooking(id).then(res => {
        // if (res.httpCode === 200 && res.data.status === 'ok') {
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    }
  },
  computed: {
    vTableFields () {
      return this.fieldsTable
    },
    process () {
      return this.$route.params.process || null
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isTheSeller () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idVendedor
      }
      return false
    },
    isTheRequester () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idSolicitante
      }
      return false
    }
  },
  watch: {
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }

  th:hover{
    color: #A69073 !important
  }
</style>
